import request from '@/utils/request'

export function getVenue(data) {
    return request({
        url: '/v2/culture/stadiums/lists',
        method: 'post',
        params: data
    })
}

export function venueInfo(data) {
    return request({
        url: '/v2/culture/stadiums/info',
        method: 'post',
        params: data
    })
}

//获取场馆信息
export function getVenueMap(data) {
    return request({
        url: 'v2/culture/venue.index/getVenueMap',
        method: 'post',
        params: data
    })
}

//获取空间信息
export function getSpace(data) {
    return request({
        url: 'v2/culture/venue.space/index',
        method: 'post',
        params: data
    })
}

//获取场馆预定详情页
export function getSpaceInfo(data) {
    return request({
        url: 'v2/culture/venue.space/info',
        method: 'post',
        params: data
    })
}


//获取文旅地图列表页
export function getMap(data) {
    return request({
        url: 'v2/culture/venue.index/lists',
        method: 'post',
        params: data
    })
}

//获取文旅地图详情页
export function getMapInfo(data) {
    return request({
        url: 'v2/culture/venue.index/info',
        method: 'post',
        params: data
    })
}