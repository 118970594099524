<template>
  <div class="map-container">
    <div class="map-crumbs">
      <router-link :to="{ name: 'culture_homepage' }">首页 / </router-link>
      <div>场馆预定</div>
    </div>
    <!-- 类型的tab切换中的假内容 -->
    <div class="map-tab-content" v-if="tabMain && tabMain.length !== 0">
      <listItem listType="maplist" :list="tabMain"></listItem>
      <div class="map-tab-show-more" v-if="showMore">
        <div @click="getMoreList">查看更多</div>
      </div>
    </div>
    <!-- 没有内容显示的页面 -->
    <Nocontent
      v-else
      title="暂无场馆，请持续关注哦～"
    ></Nocontent>
  </div>
</template>

<script>
import { getVenue } from "@/api/map/map.js";
import ListItem from "@/views/components/listItem";
import Nocontent from "@/views/components/Nocontent";
export default {
  data() {
    return {
      current: 0,
      cur: 0,
      limit: 6,
      page: 1,
      tabMain: [],
      latitude: 0,
      longitude: 0,
      showMore: true,
    };
  },
  created() {
    this.getMapList();
  },
  components: {
    ListItem,
    Nocontent
  },
  methods: {
    getMapList() {
      let params = {
        aid: process.env.VUE_APP_AID,
        page: this.page,
        page_size: this.limit,
        latitude: this.latitude,
        longitude: this.longitude,
      };
      getVenue(params).then((res) => {
        // console.log(res);
        if (res.data.datalist.length == 0) {
          this.showMore = false;
          return;
        }
        if (
          res.data.datalist.length < res.data.datalist ||
          res.data.cur_page == res.data.page_count
        ) {
          this.showMore = false;
        }
        if (this.page == 1) {
          this.tabMain = res.data.datalist;
        } else {
          this.tabMain = this.tabMain.concat(res.data.datalist);
        }
      });
    },
    getMoreList() {
      this.page = this.page + 1;
      this.getMapList();
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/map.scss";
</style>