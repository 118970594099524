<template>
  <div class="nocontent-index">
    <img class="nocontent-index-null" src="https://image.bookgo.com.cn/webculture/jm/search/common-noContent.png" alt="" />
    <div class="nocontent-index-msg">{{ title }}</div>
  </div>
</template>

<script>
export default {
  props: {
    title: {
      type: String,
      default: "",
    },
  },
};
</script>

<style lang="scss">
@import "../../assets/scss/no_content.scss";
</style>