import { render, staticRenderFns } from "./Nocontent.vue?vue&type=template&id=5ff76e3b&"
import script from "./Nocontent.vue?vue&type=script&lang=js&"
export * from "./Nocontent.vue?vue&type=script&lang=js&"
import style0 from "./Nocontent.vue?vue&type=style&index=0&id=5ff76e3b&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports